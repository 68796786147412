<template>
  <v-container >
    <v-row >
      <v-snackbar v-model="alerta.activo" multi-line vertical top right :color="alerta.color" > 
        <strong> {{alerta.texto}} </strong>
        <template v-slot:action="{ attrs }">
          <v-btn dark text @click="alerta.activo = false" v-bind="attrs"> Cerrar </v-btn>
        </template>
      </v-snackbar>

      <v-btn fab fixed bottom left  color="primary" onClick="history.go(-1);"  > <v-icon >mdi-arrow-left-thick</v-icon> </v-btn>


      <v-col cols="12"  class="pa-4">
        <v-toolbar class="font-weight-black text-xs-subtitle-1 text-sm-h6 text-md-h6 shadowCard" color="primary" dark>
          LISTA DE DESEOS 
          <v-spacer/>
          <v-btn small fab icon text class="mx-2" @click="initialize()">
            <v-icon color="white"> mdi-autorenew </v-icon>
          </v-btn>
        </v-toolbar>
      </v-col>

      <v-container style="height: 400px;" v-if="Loading">
        <loading/>
      </v-container>

      <v-col cols="12" sm="10" md="8" xl="7" v-if="!Loading && !lista_deseos.length">
        <v-alert icon="mdi-comment-alert-outline" prominent text type="info" >
          <v-row align="center">
            <v-col class="grow text-h6">
              Actualmente no se cuenta con ningun producto deseado.
            </v-col>
            <v-col class="shrink">
              <v-btn large fab color="accent" @click="initialize()"><v-icon > mdi-autorenew </v-icon></v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>

      <v-col cols="12" sm="6"   v-for="(item, i) in lista_deseos" :key="i" v-else>
        <v-card class="shadowCard">
           <v-list-item  >
            <v-list-item-avatar :size="$vuetify.breakpoint.name === 'xs'? 60:110" tile>
              <v-img :src="url + item.foto" v-if="item.foto" loading="lazy"></v-img>
              <v-img :src="`${url + photo}`"  v-else loading="lazy"/>
            </v-list-item-avatar>
            <v-list-item-content >
              <v-list-item-subtitle class="pb-0 text-xs-caption text-sm-subtitle-1">
               <span class="font-weight-black" v-if="$vuetify.breakpoint.name != 'xs'">  Codigo: </span> {{ item.codigo }}  
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-xs-caption text-sm-subtitle-1">
               <span class="font-weight-black " v-if="$vuetify.breakpoint.name != 'xs'">  Producto : </span>  {{ item.nomart}}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-xs-caption text-sm-subtitle-1">
                <span class="font-weight-black " v-if="$vuetify.breakpoint.name != 'xs'">  Precio:  </span>     {{ item.precio1 }} MX.
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action >
              <v-btn fab small color="error" @click="modalEliminar= true; idAEliminar = item.idlista ">
                <v-icon  > mdi-delete </v-icon>
              </v-btn>
            </v-list-item-action>
           
          </v-list-item>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text color="secondary"  
              v-if="item.estatus === 1" 
              :to="{ name: 'detalle.del.producto',params:{id:item.id_producto}}"
            > Mas detalle 
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn 
              color="primary" 
              @click="agregar_a_carrito(item)"
              v-if="item.estatus === 1"
              outlined
            > 
              <v-icon>mdi-cart</v-icon> 
            </v-btn>
            <v-btn text color="error" v-else> Sin existencia </v-btn>
          </v-card-actions>
        </v-card> 
      </v-col>

      <v-dialog v-model="modalDireccion" width="800">
        <v-card class="pa-4" dark>
          <controlDireccionFacturacion 
          :parametros="parametros"
          :modo="modo"
           @modal="modalDireccion = $event" />
        </v-card>
      </v-dialog>

      <v-dialog v-model="modalEliminar" persistent max-width="500">
        <v-card >
          <v-card-title class="subtitle-1 font-weight-black" > EL PRODUCTO SE ELIMINARA DE TÚ LISTA  </v-card-title>
          <v-card-subtitle class="subtitle-2 font-weight-black">¿ ESTA SEGURO DE QUERER CONTINUAR ?</v-card-subtitle>
          <v-divider class="my-0 py-3" ></v-divider>
          <v-card-subtitle align="center" class="red--text font-weight-bold "> SE ELIMINARA DE FORMA DEFINITIVA </v-card-subtitle>
          <v-divider class="my-0 py-2 " ></v-divider>
          <v-card-actions>
            <v-btn dark outlined color="error" @click="modalEliminar = false">No,regresar</v-btn>
            <v-spacer/>
            <v-btn dark color="success" @click="eliminar_producto_lista()" >Si, Continuar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
		  <overlay v-if="overlay"/>
   </v-row>
  </v-container>
</template>

<script>
	import {mapGetters, mapActions} from 'vuex'
  import loading         from '@/components/loading.vue'
  import overlay         from '@/components/overlay.vue'
  import controlDireccionFacturacion from '@/views/Cliente/control_direccion_facturacion.vue'
	import metodos from '@/mixins/metodos.js';

  export default {
		mixins:[metodos],
    components:{
      loading,
      overlay,
      controlDireccionFacturacion
    },
    data(){
      return {
        lista_deseos:[],
        Loading: false,
        modalDireccion: false,
        parametros: {},
        modo: 1,
        alerta: { activo: false, texto:'', color:'error', vertical:true },
        overlay: false,

        modalEliminar: false,
        idAEliminar: null,

        url   : this.$http.options.root + 'fotos-fetish/' ,
        photo : 'nophoto.png',
      }
    },

    created () {
      this.initialize();
    },

    computed: {
      ...mapGetters('login',['getusuarioFetish']),
      
    },

    methods: {
      // ...mapActions('Direcciones',['obtener_direcciones_facturacion']),
      ...mapActions('Carrito',['carritoadd','CerrarCarrito']),

      initialize () {
        this.lista_deseos = [] ;this.Loading = true; 
        this.$http.get('obtener.lista.deseos/'+ this.getusuarioFetish.idcliente).then( response =>{
          this.lista_deseos = response.body
        }).catch( error =>{
          console.log('error', error)
        }).finally(() =>{
          this.Loading = false
        })
        // this.obtener_direcciones_facturacion(this.getusuarioFetish.idcliente).finally(()=>{ this.Loading = false})
      },

      validar_modo_vista(modo=1, item= {}){
        this.modo = modo;
        this.parametros = item;
        this.modalDireccion = true;
      },

      eliminar_producto_lista(){
        this.overlay = true; this.modalEliminar = false;
        this.$http.delete('eliminar.producto.lista.deseos/' + this.idAEliminar ).then( response =>{
					this.alerta = { activo: true, texto: response.body.message , color:'success'};
          this.initialize()
        }).catch( error =>{
          console.log('error', error)
					this.alerta = { activo: true, texto: error.body.message , color:'error'};
        }).finally(()=>{ this.overlay = false})
      },

      agregar_a_carrito(item){
        const payload = new Object({
          id        : item.id,
          codigo    : item.codigo,
          nomart    : item.nomart, 
          precio1   : item.precio1,
          precio2   : item.precio2,
          pjedesc   : item.pjedesc,
          descrip   : item.descrip,
          descripLarga : item.descripLarg,
          largo     : item.largo,
          ancho     : item.ancho,
          alto      : item.alto,
          peso      : item.peso,
          foto      : item.foto,
          envoltorio: item.envoltorio,
          cantidad  : 1
        })

        this.carritoadd(payload).then( response =>{
          // this.alerta = { activo: true, texto:`El producto se agrego al carrito`, color:'blue' }
          this.ver_carrito()
        })
      },

      // agregar_a_carrito(item){
      //  console.log('iteeeeem',item)
        // const payload = new Object({
        //   id        : item.id,
        //   codigo    : item.codigo,
        //   nomart    : item.nomart, 
        //   precio1   : item.precio1,
        //   precio2   : item.precio2,
        //   pjedesc   : item.pjedesc,
        //   descrip   : item.descrip,
        //   descripLarga : item.descripLarg,
        //   largo     : item.largo,
        //   ancho     : item.ancho,
        //   alto      : item.alto,
        //   peso      : item.peso,
        //   foto      : item.foto,
        //   envoltorio: item.envoltorio,
        //   cantidad  : 1
        // })

        // this.carritoadd(payload).then( response =>{
        //   this.ver_carrito();
        // })
      // },
    },

     



  }
</script>